<template>
  <CRow>
    <CCol col="12" lg="6">
      <AItemViewer :options="{ id: $route.params.id, url: 'admin/property_invoice_notes', route: '/admin/processes/invoice_notes', name: 'property_invoice_note' }" />
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'InvoiceNote'
}
</script>
